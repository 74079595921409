import React from 'react';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import PropTypes from 'prop-types';
import axios from 'axios';
import FormData from 'form-data';

//import { ViewportSendToReportForm } from '@ohif/ui';
import ViewportSendToReportForm from '../../../platform/ui/src/components/content/viewportDownloadForm/ViewportSendToReportForm';
import { utils } from '@ohif/core';

import { getEnabledElement } from './state';
import { servicesManager } from '../../../platform/viewer/src/App';
import useQuery from '../../../platform/viewer/src/customHooks/useQuery';

const MINIMUM_SIZE = 100;
const DEFAULT_SIZE = 512;
const MAX_TEXTURE_SIZE = 3000;

const SendToReportForm = ({ onClose, activeViewportIndex }) => {
  const activeEnabledElement = getEnabledElement(activeViewportIndex);

  const enableViewport = viewportElement => {
    if (viewportElement) {
      cornerstone.enable(viewportElement);
    }
  };

  const disableViewport = viewportElement => {
    if (viewportElement) {
      cornerstone.disable(viewportElement);
    }
  };

  const updateViewportPreview = (viewportElement, downloadCanvas, fileType) =>
    new Promise(resolve => {
      cornerstone.fitToWindow(viewportElement);

      viewportElement.addEventListener(
        'cornerstoneimagerendered',
        function updateViewport(event) {
          const enabledElement = cornerstone.getEnabledElement(event.target)
            .element;
          const type = 'image/' + fileType;
          const dataUrl = downloadCanvas.toDataURL(type, 1);

          let newWidth = enabledElement.offsetHeight;
          let newHeight = enabledElement.offsetWidth;

          if (newWidth > DEFAULT_SIZE || newHeight > DEFAULT_SIZE) {
            const multiplier = DEFAULT_SIZE / Math.max(newWidth, newHeight);
            newHeight *= multiplier;
            newWidth *= multiplier;
          }

          resolve({ dataUrl, width: newWidth, height: newHeight });

          viewportElement.removeEventListener(
            'cornerstoneimagerendered',
            updateViewport
          );
        }
      );
    });

  const loadImage = (activeViewport, viewportElement, width, height) =>
    new Promise(resolve => {
      if (activeViewport && viewportElement) {
        const enabledElement = cornerstone.getEnabledElement(activeViewport);
        const viewport = Object.assign({}, enabledElement.viewport);
        delete viewport.scale;
        viewport.translation = {
          x: 0,
          y: 0,
        };

        cornerstone.loadImage(enabledElement.image.imageId).then(image => {
          cornerstone.displayImage(viewportElement, image);
          cornerstone.setViewport(viewportElement, viewport);
          cornerstone.resize(viewportElement, true);

          const newWidth = Math.min(width || image.width, MAX_TEXTURE_SIZE);
          const newHeight = Math.min(height || image.height, MAX_TEXTURE_SIZE);

          resolve({ image, width: newWidth, height: newHeight });
        });
      }
    });

  const toggleAnnotations = (toggle, viewportElement) => {
    cornerstoneTools.store.state.tools.forEach(({ name }) => {
      if (toggle) {
        cornerstoneTools.setToolEnabledForElement(viewportElement, name);
      } else {
        cornerstoneTools.setToolDisabledForElement(viewportElement, name);
      }
    });
  };

  const blobToBase64 = blob => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const sendBlobToAPI = async (blob, filename, fileType) => {
    const { UINotificationService } = servicesManager.services;
    const file = `${filename}.${fileType}`;
    const base64Image = await blobToBase64(blob);
    const base64FinalImage = 'data:image/png;base64,' + base64Image;
    const reportToken = getQueryParam('report');
    const studyInstanceUID = localStorage.getItem('studyInstanceUID');
    console.log('studyInstanceUIDs: ' + studyInstanceUID);

    const formData = new FormData();
    formData.append('study_uid', studyInstanceUID);
    formData.append('image_file', base64FinalImage);

    const reportOrder = localStorage.getItem('reportOrder');
    let reportURI;
    console.log(reportToken);
    console.log(studyInstanceUID);
    console.log(base64FinalImage);
    console.log(formData);

    if (reportOrder == '0') {
      reportURI = 'https://report.higiatec.com.br/store-image-attachments/';
    } else if (reportOrder == '1') {
      reportURI = 'https://testserver.higiatec.com.br/store-image-attachments/';
    } else if (reportOrder == '2') {
      reportURI = 'http://localhost:8000/';
    }

    try {
      const response = await axios.post(reportURI, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + reportToken,
        },
      });
      console.log('File uploaded successfully');
      console.log(response);

      UINotificationService.show({
        title: 'Imagem Enviada',
        message: 'A imagem foi enviada com sucesso.',
        type: 'success',
        autoClose: true,
        duration: 15000,
      });
    } catch (err) {
      console.error('An error occurred while uploading the file:', err);
      UINotificationService.show({
        title: 'Erro ao enviar imagem',
        message: 'Não foi possível enviar a imagem para o Report.',
        type: 'error',
        autoClose: true,
        duration: 15000,
      });
    }
  };

  function getQueryParam(param) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(param);
  }

  const downloadBlob = async (
    filename,
    fileType,
    viewportElement,
    downloadCanvas
  ) => {
    const mimetype = `image/${fileType}`;

    /* Handles JPEG images for IE11 */
    if (downloadCanvas.msToBlob && fileType === 'jpeg') {
      const image = downloadCanvas.toDataURL(mimetype, 1);
      const blob = utils.b64toBlob(
        image.replace('data:image/jpeg;base64,', ''),
        mimetype
      );
      return sendBlobToAPI(blob, filename, fileType);
    }

    viewportElement.querySelector('canvas').toBlob(async blob => {
      await sendBlobToAPI(blob, filename, fileType);
    }, mimetype);
  };

  return (
    <ViewportSendToReportForm
      onClose={onClose}
      minimumSize={MINIMUM_SIZE}
      maximumSize={MAX_TEXTURE_SIZE}
      defaultSize={DEFAULT_SIZE}
      canvasClass={'cornerstone-canvas'}
      activeViewport={activeEnabledElement}
      enableViewport={enableViewport}
      disableViewport={disableViewport}
      updateViewportPreview={updateViewportPreview}
      loadImage={loadImage}
      toggleAnnotations={toggleAnnotations}
      downloadBlob={downloadBlob}
    />
  );
};

SendToReportForm.propTypes = {
  onClose: PropTypes.func,
  activeViewportIndex: PropTypes.number.isRequired,
};

export default SendToReportForm;
